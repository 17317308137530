import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { invoices as defaultInvoices } from "../const";
import { getCookie } from "../js/main";
import { useNavigate } from "react-router-dom";

// var cookie = JSON.parse(localStorage.getItem(cookie_name));

export default function InvoiceTable() {
  const [payments, setPayments] = useState();
  const [invoices, setInvoice] = useState();
  useEffect(() => {
    let cookie = getCookie();
    setPayments(cookie.payments);
  }, []);

  useEffect(() => {
    if (payments) {
      let invoices = defaultInvoices;
      for (let i = 0; i < invoices.length; i++) {
        const sum = payments
          .filter((j) => j.invoice_no === invoices[i].invoice_no)
          .reduce((accumulator, object) => {
            return accumulator + (object.amount || 0);
          }, 0);
        invoices[i].paid = sum;
        invoices[i].behindhand = invoices[i].amount - sum;
      }
      setInvoice(invoices);
    }
  }, [payments]);

  return (
    <div>
      {invoices && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "40px" }} align="center">
                  No
                </TableCell>
                <TableCell sx={{ width: "70px" }} align="center">
                  Invoice
                </TableCell>
                <TableCell sx={{ width: "60px" }}>Term</TableCell>
                <TableCell>Description</TableCell>
                <TableCell sx={{ width: "80px" }}>Amount</TableCell>
                <TableCell sx={{ width: "80px" }}>Paid</TableCell>
                <TableCell sx={{ width: "80px" }}>Behindhand</TableCell>
                <TableCell sx={{ width: "80px" }}>Control</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map((invoice, key) => (
                <InvoiceRow key={key} invoice={invoice} no={key + 1} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

function InvoiceRow({ invoice, no }) {
  const navigate = useNavigate();
  return (
    <TableRow hover>
      <TableCell align="center">{no}</TableCell>
      <TableCell align="center">{invoice.invoice_no}</TableCell>
      <TableCell align="center">
        {invoice.school_term}/{invoice.school_year}
      </TableCell>
      <TableCell>{invoice.template_name}</TableCell>
      <TableCell align="center">{invoice.amount.toLocaleString()}</TableCell>
      <TableCell align="center" sx={{ color: "#0a0" }}>
        {invoice.paid.toLocaleString() || "-"}
      </TableCell>
      <TableCell align="center" sx={{ color: "#f00" }}>
        {invoice.behindhand.toLocaleString() || "-"}
      </TableCell>
      <TableCell align="center" sx={{ padding: "2px 2px" }}>
        <IconButton
          color="primary"
          sx={{ padding: "5px 5px" }}
          title="Download Invoice"
        >
          <Icon>download</Icon>
        </IconButton>
        <IconButton
          color="primary"
          sx={{ padding: "5px 5px" }}
          onClick={() =>
            navigate(process.env.PUBLIC_URL + "/payment/" + invoice.invoice_no)
          }
          title="Pay"
        >
          <Icon>payment</Icon>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

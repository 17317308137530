import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { invoices, debtor, cookie_name } from "./const";
import { Container, Divider, IconButton, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";

import dayjs from "dayjs";
import { DebtorProfile } from "./components/DebtorProfile";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getCookie } from "./js/main";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";

export default function Payment() {
  const params = useParams();
  const [invoice, setInvoice] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    if (params && params.invoice_id) {
      let i = invoices.findIndex((j) => j.invoice_no === params.invoice_id);
      if (i > -1) setInvoice(invoices[i]);
    }
  }, [params]);

  return (
    <Container>
      {invoice && (
        <>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/" component="div">
              Debtor
            </Link>
            <Link
              underline="hover"
              component="a"
              color="inherit"
              href="#"
              onClick={() => navigate(process.env.PUBLIC_URL + "/")}
            >
              {debtor.fullname}
            </Link>
            <Typography color="text.primary" component="div">
              {invoice.invoice_no}
            </Typography>
          </Breadcrumbs>
          <div style={{ margin: "40px auto 10px" }}>
            <DebtorProfile debtor={debtor} />
          </div>
          <SingleInvoiceTable invoice={invoice} />
        </>
      )}
    </Container>
  );
}

function SingleInvoiceTable(props) {
  const [invoice] = useState(props.invoice);
  const [bill, setBill] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    function sumPaid(payments, account_id) {
      let sum = 0;
      for (let p of payments) {
        for (let d of p.details.filter((j) => j.account_id === account_id)) {
          sum += d.pay_amount;
        }
      }
      return sum;
    }
    (async () => {
      if (invoice) {
        let payments = getCookie();
        payments = payments.payments.filter(
          (j) => j.invoice_no === invoice.invoice_no
        );
        let Invoice = JSON.parse(JSON.stringify(invoice));
        Invoice.details = await Invoice.details.map((item) => {
          let paid = sumPaid(payments, item.account_id);
          item.paid = parseFloat(paid);
          item.behindhand = parseFloat(item.amount) - paid;
          return item;
        });
        Invoice.amount = 0;
        // Invoice.pay_date = moment().format("YYYY-MM-DD");
        // Invoice.bill_date = new Date();
        setBill(Invoice);
      }
    })();
  }, [invoice]);
  function CalulateAmount(items) {
    const sum = items.reduce((accumulator, object) => {
      return accumulator + (object.pay_amount || 0);
    }, 0);
    return sum;
  }
  function changeBillItem(key, field) {
    let Bill = JSON.parse(JSON.stringify(bill));
    let field_name = Object.keys(field);
    Bill.details[key][field_name] = parseFloat(field[field_name]);
    Bill.amount = CalulateAmount(Bill.details);
    setBill(Bill);
  }
  function addOtherForm(form) {
    let Bill = JSON.parse(JSON.stringify(bill));
    form.pay_amount = parseFloat(form.pay_amount || 0);
    form.amount = form.pay_amount;
    form.behindhand = form.pay_amount;
    form.other_pay = true;
    Bill.details.push(form);
    Bill.amount = CalulateAmount(Bill.details);
    setBill(Bill);
  }
  function removeItem(key) {
    let Bill = JSON.parse(JSON.stringify(bill));
    Bill.details.splice(key, 1);
    setBill(Bill);
  }
  function changePayAmount(e) {
    let Bill = JSON.parse(JSON.stringify(bill));
    let amount = parseFloat(e.target.value);
    Bill.amount = amount;
    for (let i = 0; i < Bill.details.length; i++) {
      let item = Bill.details[i];
      if (item.behindhand > amount) {
        Bill.details[i].pay_amount = amount;
        amount = 0;
      } else {
        Bill.details[i].pay_amount = item.behindhand;
        amount -= item.behindhand;
      }
    }
    setBill(Bill);
  }
  function saveBill() {
    let Bill = JSON.parse(JSON.stringify(bill));
    if (Bill.payer_name && Bill.payer_name !== "") {
      let cookie = getCookie();
      Bill.year = parseInt(moment().format("YYYY")) + 543;
      Bill.bill_no = cookie.payments.length + 1;
      Bill.details = Bill.details.filter((j) => j.pay_amount > 0);
      if (!Bill.pay_date) Bill.pay_date = new Date();
      if (!Bill.bill_date) Bill.bill_date = new Date();

      Bill.pay_date = moment(new Date(Bill.pay_date)).format("YYYY-MM-DD");
      Bill.bill_date = moment(new Date(Bill.bill_date)).format("YYYY-MM-DD");
      delete Bill.behindhand;
      delete Bill.paid;
      cookie.payments.push(Bill);
      localStorage.setItem(cookie_name, JSON.stringify(cookie));
      navigate(process.env.PUBLIC_URL + "/");
    } else alert("Please fill completly information");
  }
  return (
    <Paper>
      {bill && (
        <>
          <div style={{ padding: "20px 10px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel shrink>Pay by</InputLabel>
                    <Select
                      value={bill.pay_by}
                      label="Pay by"
                      onChange={(val, val2) =>
                        setBill({ ...bill, pay_by: val2 })
                      }
                      native
                    >
                      <option value="เงินสด">เงินสด</option>
                      <option value="เงินโอน">เงินโอน</option>
                      <option value="บัตรเครดิต">บัตรเครดิต</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} style={{ paddingTop: "0px" }}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Pay Date"
                      value={bill.pay_date || dayjs(new Date())}
                      onChange={(val) => setBill({ ...bill, pay_date: val })}
                      format="DD MMM YYYY"
                      slotProps={{
                        textField: {
                          size: "small",
                          InputLabelProps: { shrink: true },
                          fullWidth: true,
                        },
                      }}
                    />
                  </DemoContainer>
                </Grid>
                <Grid item xs={4} style={{ paddingTop: "0px" }}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Bill Date"
                      format="DD MMM YYYY"
                      value={bill.bill_date || dayjs(new Date())}
                      onChange={(val) => setBill({ ...bill, bill_date: val })}
                      slotProps={{
                        textField: {
                          size: "small",
                          InputLabelProps: { shrink: true },
                          fullWidth: true,
                        },
                      }}
                    />
                  </DemoContainer>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    size="small"
                    variant="outlined"
                    value={bill.payer_name || ""}
                    label="Payer Name"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setBill({ ...bill, payer_name: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    variant="outlined"
                    value={bill.amount}
                    label="Pay Amount"
                    fullWidth
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ style: { textAlign: "right" } }}
                    onChange={changePayAmount}
                  />
                </Grid>
                <Grid item xs={12} align="right">
                  <Button
                    color="primary"
                    onClick={saveBill}
                    variant="contained"
                  >
                    Create Bill
                  </Button>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </div>
          <Divider />
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "40px" }} align="center">
                    No
                  </TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell sx={{ width: "80px" }}>Amount</TableCell>
                  <TableCell sx={{ width: "80px" }}>Paid</TableCell>
                  <TableCell sx={{ width: "80px" }}>Behindhand</TableCell>
                  <TableCell sx={{ width: "120px" }}>Payment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bill.details.map((item, key) => (
                  <BillRow
                    item={item}
                    no={key + 1}
                    key={key}
                    changeForm={(field) => changeBillItem(key, field)}
                    removeItem={() => removeItem(key)}
                  />
                ))}
                <OtherPayInput addOther={addOtherForm} />
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Paper>
  );
}

function OtherPayInput({ addOther }) {
  const [form, setForm] = useState({});
  return (
    <TableRow>
      <TableCell align="center" style={{ padding: "2px 2px" }}>
        <IconButton
          color="primary"
          onClick={() => {
            form.name !== "" && addOther(form);
            setForm({});
          }}
        >
          <Icon>add</Icon>
        </IconButton>
      </TableCell>
      <TableCell style={{ padding: "2px 2px" }}>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          value={form.name || ""}
          onChange={(e) => setForm({ ...form, name: e.target.value })}
          placeholder="Other Payment"
        />
      </TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell style={{ padding: "2px 2px" }}>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          inputProps={{ style: { textAlign: "right" } }}
          value={form.pay_amount || ""}
          onChange={(e) => setForm({ ...form, pay_amount: e.target.value })}
          type="number"
        />
      </TableCell>
    </TableRow>
  );
}

function BillRow({ item, no, changeForm, removeItem }) {
  function changeAmount(e) {
    let val = e.target.value;
    if (val > parseFloat(item.behindhand)) val = item.behindhand;
    changeForm({ pay_amount: val });
  }
  return (
    <TableRow>
      <TableCell align="center" sx={{ padding: "2px 2px" }}>
        {item.other_pay ? (
          <IconButton
            color="error"
            style={{ padding: "5px 5px" }}
            onClick={removeItem}
          >
            <Icon>clear</Icon>
          </IconButton>
        ) : (
          no
        )}
      </TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell align="right">{item.amount.toLocaleString()}</TableCell>
      <TableCell align="right" sx={{ color: "#0a0" }}>
        {item.paid ? item.paid.toLocaleString() : 0}
      </TableCell>
      <TableCell align="right" sx={{ color: "#f00" }}>
        {item.behindhand ? item.behindhand.toLocaleString() : 0}
      </TableCell>
      <TableCell sx={{ padding: "2px 2px", textAlign: "right" }}>
        {item.notPay !== true && (
          <>
            {item.other_pay === true ? (
              <>{item.pay_amount.toLocaleString()}</>
            ) : (
              <TextField
                size="small"
                variant="outlined"
                inputProps={{ style: { textAlign: "right" } }}
                value={item.pay_amount || ""}
                onChange={changeAmount}
                type="number"
              />
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );
}

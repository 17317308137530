import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { cookie_name, debtor } from "./const";
import { DebtorProfile } from "./components/DebtorProfile";
import InvoiceTable from "./components/InvoiceTable";
import BillTable from "./components/BillTable";

import React, { useState } from "react";
import { Paper } from "@mui/material";

export default function Debtor() {
  const [currentTab, setCurrentTab] = useState(0);
  return (
    <div>
      <Container>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/" component="div">
            Debtor
          </Link>
          <Typography color="text.primary" component="div">
            {debtor.fullname}
          </Typography>
        </Breadcrumbs>
        <div style={{ marginTop: "40px" }}>
          <DebtorProfile debtor={debtor} />
        </div>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", marginTop: "20px" }}
          component={Paper}
        >
          <Tabs
            value={currentTab}
            onChange={(val, val2) => setCurrentTab(val2)}
          >
            <Tab label="หนี้สิน" component="div" sx={{ fontSize: "1rem" }} />
            <Tab
              label="การชำระเงิน"
              component="div"
              sx={{ fontSize: "1rem" }}
            />
          </Tabs>
          <div style={{ padding: "10px 5px" }}>
            {{ 0: <InvoiceTable />, 1: <BillTable /> }[currentTab]}
          </div>
        </Box>
        <Grid container sx={{ marginTop: "20px" }}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                localStorage.removeItem(cookie_name);
                window.location = process.env.PUBLIC_URL + "/";
              }}
            >
              Clear All Bill
            </Button>
          </Grid>
          <Grid item xs={6} align="right">
            <Button variant="contained" color="primary">
              Print Invoices
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

exports.debtor = {
  fullname: "Mr. FirstName LastName",
  group_name: "K1",
  student_code: "999",
  level: "อนุบาล 1",
  phone_number: "08-1111-1111",
  idx: "1111-1111-1111",
  address:
    "เลขที่ 292 หมู่ 6 กม.ที่ 4 ถ.เชียงใหม่-แม่โจ้ ต.หนองจ๊อม อ.สันทราย จ.เชียงใหม่ 50210",
  account: "account@tonkla.ac.th",
  student_status: "ปกติ",
};
exports.cookie_name = "finance_mockup_cookie";

exports.invoices = [
  {
    invoice_no: "00001",
    school_year: "2565",
    school_term: "1",
    template_name: "ค่าธรรมเนียมการเรียนระดับอนุบาล 1 (IP)",
    amount: 50000,
    details: [
      { account_id: "4-0001", name: "ค่าธรรมเนียมการศึกษา", amount: 30000 },
      { account_id: "4-0002", name: "ค่าธรรมเนียมอื่น ๆ", amount: 20000 },
    ],
  },
  {
    invoice_no: "00002",
    school_year: "2565",
    school_term: "2",
    template_name: "ค่าธรรมเนียมการเรียนระดับอนุบาล 1 (IP)",
    amount: 55000,
    details: [
      { account_id: "4-0001", name: "ค่าธรรมเนียมการศึกษา", amount: 30000 },
      { account_id: "4-0002", name: "ค่าธรรมเนียมอื่น ๆ", amount: 25000 },
    ],
  },
  {
    invoice_no: "00003",
    school_year: "2566",
    school_term: "1",
    template_name: "ค่าธรรมเนียมการเรียนระดับอนุบาล 2 (IP)",
    amount: 60000,
    details: [
      { account_id: "4-0001", name: "ค่าธรรมเนียมการศึกษา", amount: 35000 },
      { account_id: "4-0002", name: "ค่าธรรมเนียมอื่น ๆ", amount: 30000 },
      {
        account_id: "5-0001",
        name: "ส่วนลดลูกบุคลากร",
        amount: -5000,
        notPay: true,
      },
    ],
  },
];

import React, { useState, useEffect } from "react";
import { getCookie } from "../js/main";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import moment from "moment";

export default function BillTable() {
  const [payments, setPayments] = useState();
  useEffect(() => {
    let cookie = getCookie();
    setPayments(cookie.payments);
  }, []);

  return (
    <div>
      {payments && (
        <>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "40px" }} align="center">
                    No
                  </TableCell>
                  <TableCell sx={{ width: "50px" }} align="center">
                    Bill No.
                  </TableCell>
                  <TableCell sx={{ width: "70px" }} align="center">
                    Invoice No.
                  </TableCell>
                  <TableCell sx={{ width: "80px" }}>Pay Date</TableCell>
                  <TableCell sx={{ width: "80px" }}>Bill Date</TableCell>
                  <TableCell>Bill Description</TableCell>
                  <TableCell sx={{ width: "120px" }}>Payer</TableCell>
                  <TableCell sx={{ width: "60px" }}>Amount</TableCell>
                  <TableCell sx={{ width: "40px" }}>Control</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.length === 0 && (
                  <TableRow>
                    <TableCell
                      sx={{ lineHeight: "60px", color: "#999" }}
                      colSpan={9}
                      align="center"
                    >
                      Not found Bill
                    </TableCell>
                  </TableRow>
                )}
                {payments.map((bill, key) => (
                  <TableRow key={key}>
                    <TableCell align="center">{key + 1}</TableCell>
                    <TableCell align="center">
                      {bill.year}-{bill.bill_no}
                    </TableCell>
                    <TableCell align="center">{bill.invoice_no}</TableCell>
                    <TableCell align="center">
                      {moment(bill.pay_date).format("DD MMM YYYY")}
                    </TableCell>
                    <TableCell align="center">
                      {moment(bill.bill_date).format("DD MMM YYYY")}
                    </TableCell>
                    <TableCell>{bill.template_name}</TableCell>
                    <TableCell align="center">{bill.payer_name}</TableCell>
                    <TableCell align="center">
                      {bill.amount.toLocaleString()}
                    </TableCell>
                    <TableCell align="center" sx={{ padding: "0px 0px" }}>
                      <IconButton sx={{ padding: "5px 5px" }}>
                        <Icon>more_vert</Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
}

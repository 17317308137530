import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

export const DebtorProfile = ({ debtor }) => (
  <Grid container>
    <Grid item xs={12} md={2} sx={{ paddingBottom: "20px" }} align="center">
      <Avatar alt={debtor.fullname} sx={{ width: "120px", height: "120px" }} />
    </Grid>
    <Grid item xs={12} md={4}>
      <Grid container>
        <ProfileField label="ชื่อ-นามสกุล" value={debtor.fullname} />
        <ProfileField
          label="รหัสนักเรียน"
          value={debtor.student_code + " (" + debtor.student_status + ")"}
        />
        <ProfileField label="ID Card / Passport" value={debtor.idx} />
        <ProfileField label="กลุ่มเรียน" value={debtor.group_name} />
      </Grid>
    </Grid>
    <Grid item xs={12} md={6}>
      <Grid container>
        <ProfileField label="ระดับชั้น" value={debtor.level} />
        <ProfileField label="ที่อยู่" value={debtor.address} />
        <ProfileField label="เบอร์โทรศัพท์" value={debtor.phone_number} />
        <ProfileField label="e-Mail" value={debtor.account} />
      </Grid>
    </Grid>
  </Grid>
);

const ProfileField = ({ label, value }) => (
  <>
    <Grid
      item
      xs={3}
      align="right"
      style={{ fontSize: ".9rem", lineHeight: "1.5rem", fontWeight: 500 }}
    >
      {label}
    </Grid>
    <Grid
      item
      xs={9}
      style={{ paddingLeft: "20px", fontSize: ".9rem", lineHeight: "1.5rem" }}
    >
      <Stack direction="row" spacing={2}>
        <div>:</div>
        <div>{value}</div>
      </Stack>
    </Grid>
  </>
);
